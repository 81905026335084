
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 27px;
  color: white;
  background-color: var(--brand-primary, black);
  @include bp-lg-laptop {
    justify-content: center;
  }
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main-logo {
    width: 100%;
    max-width: 200px;
  }
  .digital-logo {
    width: 14.5em;
    margin-top: 20px;
    margin-bottom: 1em;
  }
  .dashboard-text {
    margin-bottom: 2em;
    font-weight: bold;
    text-transform: uppercase;
  }
  h3 {
    margin: 0;
    margin-top: -20px;
    margin-left: -10px;
    color: white;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 408px;
    padding: 50px;
    margin-top: 40px;
    background-color: white;
    @include bp-lg-laptop {
      margin-top: 50px;
    }

    ::v-deep {
      .gk-input {
        padding: 0;
        .input-element {
          padding: 10px 0;
        }
      }
      .password {
        margin-top: 22px;
      }
      .submit {
        justify-content: center;
        width: 100%;
        margin-top: 32px;
      }
    }
  }
}
