
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  ::v-deep {
    .fields {
      width: 100%;
    }
  }
  .g-input {
    width: 100%;
  }
}
